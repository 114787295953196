import {Button, Grid, IconButton, Menu, MenuItem, Popover, styled, Tab, Tabs} from "@mui/material";
import {PropsWithChildren, useState} from "react";
import { UploadData } from "./UploadData";
import {ArrowDownward, Home, Person, Settings, VerifiedUser} from "@mui/icons-material";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {grey} from "@mui/material/colors";
import moment from "moment";
import { UserInfo } from "./UserInfo";
import {Search} from "./Search";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const TabWrapper = styled("div")(({theme}) => ({
    borderBottom: "2px solid " + grey["900"],

    "& a": {
        textDecoration: "none",
    },

    "& button.MuiButtonBase-root": {
        color: grey["900"],
    },
    "& .MuiTabs-indicator": {
        backgroundColor: theme.palette.secondary.main,
        height: 3,
    }
}))

export function Container(props: PropsWithChildren<{}>) {
    const location = useLocation();
    const nav = useNavigate()
    const [showUpload, setShowUpload] = useState(false);
    const [reportPopover, setReportPopover] = useState(null as null | HTMLElement);

    return (
        <div style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            backgroundColor: "#49c3f1",
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
        }}>
            <header style={{padding: 16}}>
                <Grid container justifyContent="space-between" spacing={1}>
                    <Grid item>
                        <img src="/logo-big.svg" style={{height: 50}} />
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <Search />
                            </Grid>
                            <Grid item>
                                <TabWrapper>
                                    <Tabs value={
                                        location.pathname === "/" ? "dashboard" :
                                        location.pathname.startsWith("/report") ? "report" :
                                        location.pathname === "/plan-trip" ? "plan-trip" :
                                        location.pathname === "/settings" ? "settings" : "-"
                                    }
                                          onChange={() => {}}
                                    >
                                        <Tab value="dashboard" onClick={() => nav("/")} label="Dashboard" style={{color: grey["900"]}} />

                                        <Tab value="report" onClick={(e) => setReportPopover(e.currentTarget as any)}
                                             label={<div style={{display: "flex", alignItems: 'center'}}>Report <ArrowDropDownIcon /></div>} style={{color: grey["900"]}} />
                                        {reportPopover && <Menu anchorEl={reportPopover} open onClose={() => setReportPopover(null)}>
                                            <MenuItem>
                                                <Link style={{textDecoration: "none", color: "initial"}} onClick={() => setReportPopover(null)} to="/report/standard">Standard Report</Link>
                                            </MenuItem><MenuItem>
                                                <Link style={{textDecoration: "none", color: "initial"}} onClick={() => setReportPopover(null)} to="/report/pivot">Pivot Report</Link>
                                            </MenuItem><MenuItem>
                                                <a style={{textDecoration: "none", color: "initial"}} onClick={() => setReportPopover(null)} href="/api/pre-built-reports/store-locator">Download Store Locator Report</a>
                                            </MenuItem>
                                        </Menu>}

                                        <Tab value="plan-trip" onClick={() => nav("/plan-trip")} label="Plan Trip" style={{color: grey["900"]}} />
                                        <Tab value="upload" onClick={()=> setShowUpload(true)} label="Upload" style={{color: grey["900"]}} />
                                        <Tab value="settings" onClick={() => nav("/settings")} label="Settings" style={{color: grey["900"]}} />
                                    </Tabs>
                                    <UploadData show={showUpload} onDone={() => setShowUpload(false)} />
                                </TabWrapper>
                            </Grid>
                            <Grid item>
                                <UserInfo />
                            </Grid>
                            <Grid item>
                                <div style={{width: 20}} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </header>
            <div style={{flex: 1}}>
                {props.children}
            </div>
            <footer style={{fontSize: "0.8rem", color: grey["800"], padding: 4, paddingTop: 8}}>
                <Grid container justifyContent="center" spacing={4}>
                    <Grid item>
                        Version {moment(process.env.REACT_APP_BUILD_DATE).format("MMM-DD.H")}.
                        {process.env.REACT_APP_VERSION}
                    </Grid>
                    <Grid item>
                        Something wrong? <a href="mailto:nbosscher@blue-giraffe.ca">nbosscher@blue-giraffe.ca</a>
                    </Grid>
                    <Grid item>
                        Built with ♥ by <a href="https://blue-giraffe.ca">Blue Giraffe Software</a>
                    </Grid>
                </Grid>
            </footer>
        </div>
    )
}